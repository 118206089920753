var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container online-info-page"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"size":"small","rules":_vm.rules,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"角色名称","prop":"roleName"}},[_c('el-input',{staticClass:"input-750",attrs:{"placeholder":"角色名称"},model:{value:(_vm.form.roleName),callback:function ($$v) {_vm.$set(_vm.form, "roleName", $$v)},expression:"form.roleName"}})],1),_c('el-form-item',{attrs:{"label":"描述","prop":"remark"}},[_c('el-input',{staticClass:"input-750",attrs:{"type":"textarea","placeholder":"角色描述"},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(false),expression:"false"}],attrs:{"span-method":_vm.objectSpanMethod,"data":_vm.tableData}},[_c('el-table-column',{attrs:{"label":"系统模块","align":"center","width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
var $index = ref.$index;
return [_c('el-checkbox',{on:{"change":function($event){return _vm.checkRow(row.parent.menuId,$event)}},model:{value:(_vm.checkboxMap[row.parent.menuId].value),callback:function ($$v) {_vm.$set(_vm.checkboxMap[row.parent.menuId], "value", $$v)},expression:"checkboxMap[row.parent.menuId].value"}},[_vm._v(" "+_vm._s(row.parent.name)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"操作权限","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
var $index = ref.$index;
return [[_c('div',{staticClass:"row-warp row"},[_c('span',{staticClass:"module-name"},[_vm._v(_vm._s(row.name))]),_c('div',{staticClass:"checkbox-list"},_vm._l((_vm.tableData[$index].permission),function(module,moduleIdx){return _c('el-checkbox',{model:{value:(_vm.checkboxMap[module.menuId].value),callback:function ($$v) {_vm.$set(_vm.checkboxMap[module.menuId], "value", $$v)},expression:"checkboxMap[module.menuId].value"}},[_vm._v(" "+_vm._s(module.name)+" ")])}),1)])]]}}])})],1),_c('div',[_c('div',{staticClass:"dialog-footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("确 定")]),_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取 消")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }