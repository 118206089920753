<template>
  <div class="app-container online-info-page">
    <el-form ref="form" :model="form" size="small" :rules="rules" label-width="80px">
      <el-form-item label="角色名称" prop="roleName">
        <el-input class="input-750" v-model="form.roleName" placeholder="角色名称" />
      </el-form-item>

      <el-form-item label="描述" prop="remark">
        <el-input type="textarea" class="input-750" v-model="form.remark" placeholder="角色描述" />
      </el-form-item>
    </el-form>

    <el-table :span-method="objectSpanMethod" v-loading="false" :data="tableData">
      <el-table-column label="系统模块" align="center" width="150px">
        <template slot-scope="{row, column, $index }">
          <el-checkbox @change="checkRow(row.parent.menuId,$event)" v-model="checkboxMap[row.parent.menuId].value">
            {{ row.parent.name }}
          </el-checkbox>
        </template>
      </el-table-column>

      <el-table-column label="操作权限" align="left">
        <template slot-scope="{row, column, $index }">
          <template>
            <div class="row-warp row">
              <span class="module-name">{{ row.name }}</span>
              <div class="checkbox-list">
                <el-checkbox v-for="(module,moduleIdx) in tableData[$index].permission"
                             v-model="checkboxMap[module.menuId].value">
                  {{ module.name }}
                </el-checkbox>
              </div>
            </div>
          </template>
        </template>
      </el-table-column>

      <!-- <el-table-column label="字段权限" align="left" width="750px">
        <template slot-scope="{row, column, $index }">
          <template>
            <div class="row-warp row">
              <div class="checkbox-list">
                <el-checkbox v-for="(module,moduleIdx) in tableData[$index].field"
                             v-model="checkboxMap[module.menuId].value">
                  {{ module.name }}
                </el-checkbox>
              </div>
            </div>
          </template>
        </template>
      </el-table-column> -->
    </el-table>

    <div>
      <div class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import {
  sysCompanyRoleMenuList, sysCompanyRoleSave, sysCompanyRoleUpdate,
  sysCompanyRoleDelete,
  sysCompanyRoleInfo,
} from '@/api/offline.js';

export default {

  data() {
    return {
      checkboxMap: {},
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,

      cityList: [],

      // 表单参数
      form: {
        diffFlag: 2,
        largeMedicalPayType: 1,
        insuranceEnterValidType: 1,
        fundEnterValidType: 1,
        insuranceLeaveValidType: 1,
        fundLeaveValidType: 1,
        insuranceChangeEnterValidType: 1,
        fundChangeEnterValidType: 1,
        insurancePayBackMonth: 0,
        fundPayBackMonth: 0
      },
      // 表单校验
      rules: {
        roleName: [
          {
            required: true,
            message: '不能为空',
            trigger: 'blur'
          },
        ],
      },
      configStatus: 1,
    };
  },
  created() {
    // this.getList();
    this.sysCompanyRoleMenuList();
  },
  methods: {
    // 获取权限
    sysCompanyRoleMenuList() {
      sysCompanyRoleMenuList()
        .then(({ data }) => {
          this.tableData = this.handleRoleTableData(data);

          const { id } = this.$route.params;
          if (this.$route.params.id !== '-1') {
            setTimeout(() => {
              document.title = '编辑角色';
              this.handleUpdate(id);
            }, 200);
          } else {
            setTimeout(() => {
              document.title = '新增角色';
              this.handleAdd();
            }, 200);
          }

          // handleRoleTableData
        });
    },

    // 选中整个行
    checkRow(pid, value) {
      const modules = _.filter(this.checkboxMap, (value) => value.parentId === pid);
      _.each(modules, (x) => {
        // this.checkboxMap[x.menuId].value = value;
        _.each(x.field, (f) => {
          this.checkboxMap[f.menuId].value = value;
        });

        const permission = _.filter(this.checkboxMap, (value) => value.parentId === x.menuId);
        _.each(permission, (p) => {
          this.checkboxMap[p.menuId].value = value;
        });
      });

      //
      //
      //
      // const {
      //   permission,
      //   field
      // } = this.tableData[idx];
      // _.each(permission, p => {
      //   this.checkboxMap[p.menuId].value = value;
      // });
      //
      // _.each(field, f => {
      //   this.checkboxMap[f.menuId].value = value;
      // });
    },
    objectSpanMethod({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      // debugger
      if (columnIndex === 0) {
        if (row.hidden) {
          return {
            colspan: 0,
            rowspan: 0,
          };
        }
        return {
          colspan: 1,
          rowspan: _.get(row, ['parent', 'child', 'length'], 1),
        };
      }
      return {
        rowspan: 1,
        colspan: 1
      };
    },

    handleRoleTableData(data) {
      let tempItem;
      const [systemModule, businessModule, permission, field] = [[], [], [], []];
      // 设置checkboxMap
      _.each(data, (x) => {
        x.value = false;
        tempItem = x;
        if (x.type === 0) {
          systemModule.push(x);
        } else if (x.type === 1) {
          businessModule.push(x);
        } else if (x.type === 2) {
          permission.push(x);
        } else if (x.type === 3) {
          field.push(x);
        }
        this.checkboxMap[x.menuId] = tempItem;
      });

      console.log(systemModule, businessModule, permission, field);

      _.each(systemModule, (x) => {
        const child = _.filter(businessModule, (z) => z.parentId === x.menuId);
        x.child = child;
      });

      const has = new Set();
      _.each(businessModule, (x) => {
        const child = _.filter(permission, (z) => z.parentId === x.menuId);
        x.permission = child;

        x.parent = this.checkboxMap[x.parentId];

        // debugger;
        if (has.has(x.parent)) {
          // has
          x.hidden = true;
        } else {
          has.add(x.parent);
        }
      });

      _.each(businessModule, (x) => {
        const child = _.filter(field, (z) => z.parentId === x.menuId);
        x.field = child;
      });

      console.log(systemModule);
      console.log(businessModule);
      console.log(permission);
      console.log(field);

      return _.orderBy(businessModule, ['parentId'], ['asc']);
    },
    // 取消按钮
    cancel() {
      this.$router.back();
    },
    // 表单重置
    reset() {
      this.form = {
        menuIdList: [],
        remark: '',
        roleName: ''
      };
      this.resetForm('form');
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '新增信息';
      if (this.cityList.length > 0) {
        this.form.cityRegion = this.cityList[0].key;
      }
    },
    /** 修改按钮操作 */
    handleUpdate(id) {
      this.reset();
      sysCompanyRoleInfo(id)
        .then((response) => {
          this.form = {
            ...response.data,
          };
          const { menuIdList } = response.data;
          _.each(menuIdList, (x) => {
            if (this.checkboxMap[x]) {
              this.checkboxMap[x].value = true;
            }
          });
          this.open = true;
          this.title = '修改信息';
        });
    },
    enableItem(row) {
      sysCompanyRoleUpdate({
        id: row.id,
        positionStatus: row.positionStatus === 1 ? 2 : 1,
      })
        .then((response) => {
          this.msgSuccess('修改成功');
          this.getList();
        });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
            menuIdList: [],
          };
          _.each(this.checkboxMap, (x) => {
            console.log(x.menuId, x.value);
            if (x.value) {
              params.menuIdList.push(x.menuId);
            }
          });

          if (params.roleId != undefined) {
            sysCompanyRoleUpdate(params)
              .then((response) => {
                this.msgSuccess('修改成功');
                this.$router.back();
                // this.open = false;
                // this.getList();
              });
          } else {
            sysCompanyRoleSave(params)
              .then((response) => {
                this.msgSuccess('新增成功');
                this.$router.back();
              });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('确定要删除选中的记录？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => sysCompanyRoleDelete([ids]))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {
        });
    },
  }
};
</script>

<style lang="less" scoped>
.online-info-page {
  .el-table {
    ///deep/ td, /deep/ th {
    //  padding: 0 !important;
    //}

    ///deep/ .cell {
    //  padding: 0 !important;
    //
    .row-warp {
      //border-bottom: 1px solid #1890ff;
      margin: 5px 0;
      display: flex;
      //flex-wrap: wrap;
      border-bottom: 1px solid #A4BFFE;
      padding: 3px 15px;
      align-items: center;

      &:last-child {
        border: none;
      }
    }

    //}
  }

  table {
    td {
      //padding: 10px 5px;
      //margin:0 5px;
    }

    .module-name {
      font-size: 14px;
      margin-right: 15px;
      min-width: 100px;
    }

    .checkbox-list {
      display: flex;
      flex-wrap: wrap;
    }

  }

  .dialog-footer {
    width: 280px;
    margin: 10px auto;
  }

  /deep/ .quill-editor {
    height: 300px;
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }

}

</style>
